
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DatePicker from 'react-datepicker';
import { addMonths, format, subDays, subMonths } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import Autocomplete from '@mui/material/Autocomplete';
import { Button, TextField } from "@mui/material";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { BsLightbulbFill } from "react-icons/bs";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import axios from "axios";
import '../Add-ReturnBill/AddReturnbill.css'
import Header from '../../../Header';
import Loader from '../../../../componets/loader/Loader';
import { toast, ToastContainer } from 'react-toastify';

const AddReturnbill = () => {
    const token = localStorage.getItem("token")
    const history = useHistory();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(addMonths(new Date(), 6));
    const [isLoading, setIsLoading] = useState(false);
    const [mrp, setMRP] = useState()
    const [ptr, setPTR] = useState()
    const [billNo, setBillNo] = useState()
    const [gst, setGst] = useState({ id: '', name: '' });
    const [selectedEditItemId, setSelectedEditItemId] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(1);
    const [returnType, setReturnType] = useState(null);
    const [ItemId, setItemId] = useState('')
    const [IsDelete, setIsDelete] = useState(false);
    const inputRef1 = useRef();
    const inputRef2 = useRef();
    const inputRef3 = useRef();
    const inputRef4 = useRef();
    const inputRef5 = useRef();
    const inputRef6 = useRef();
    const inputRef7 = useRef();
    const inputRef8 = useRef();
    const inputRef9 = useRef();
    const inputRef10 = useRef();
    const inputRef12 = useRef();
    const [unit, setUnit] = useState('');
    const [schAmt, setSchAmt] = useState('');
    const [disc, setDisc] = useState('');
    const [selectedEditItem, setSelectedEditItem] = useState(null);
    const [isDeleteAll, setIsDeleteAll] = useState(false);
    const [errors, setErrors] = useState({});
    const [batchList, setBatchList] = useState([]);
    const [gstList, setGstList] = useState([]);
    const [ItemTotalAmount, setItemTotalAmount] = useState(0);
    const [loc, setLoc] = useState('');
    const [distributorList, setDistributorList] = useState([]);
    const [returnItemList, setReturnItemList] = useState([])
    const [distributor, setDistributor] = useState(null);
    const [otherAmt, setOtherAmt] = useState(0)
    const [remark, setRemark] = useState('')
    const [expiryDate, setExpiryDate] = useState('');
    const [qty, setQty] = useState('')
    const [free, setFree] = useState('')
    const [error, setError] = useState({ distributor: '', returnType: '', billNo: '', startDate: '', endDate: '' });
    const staffOptions = [{ value: 'Owner', id: 1 }, { value: localStorage.getItem('UserName'), id: 2 },]
    const [batch, setBatch] = useState('')
    const [searchItem, setSearchItem] = useState('')
    const [itemPurchaseId, setItemPurchaseId] = useState('');

    const [paymentType, setPaymentType] = useState('cash');
    const [bankData, setBankData] = useState([]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (event.target === inputRef1.current) {
                inputRef2.current.focus();
            } else if (event.target === inputRef2.current) {
                inputRef3.current.focus();
            } else if (event.target === inputRef3.current) {
                inputRef4.current.focus();
            } else if (event.target === inputRef4.current) {
                inputRef5.current.focus();
            } else if (event.target === inputRef5.current) {
                inputRef6.current.focus();
            } else if (event.target === inputRef6.current) {
                inputRef7.current.focus();
            } else if (event.target === inputRef7.current) {
                inputRef8.current.focus();
            } else if (event.target === inputRef8.current) {
                inputRef9.current.focus();
            }
        };
    }
    const paymentOptions = [
        { id: 1, label: 'Cash' },
        { id: 2, label: 'Credit' },
        { id: 3, label: 'UPI' },
        { id: 4, label: 'Cheque' },
        { id: 5, label: 'Paytm' },
        { id: 6, label: 'CC/DC' },
        { id: 7, label: 'RTGS/NEFT' }]

    useEffect(() => {
        listOfGst();
        listDistributor();
        BankList();
        setBillNo(localStorage.getItem('Purchase_Return_BillNo'));
    }, [])

    useEffect(() => {
        const totalSchAmt = parseFloat((((ptr * disc) / 100) * qty).toFixed(2));
        const totalBase = parseFloat(((ptr * qty) - totalSchAmt).toFixed(2));
        const totalAmount = parseFloat((totalBase + (totalBase * gst.name / 100)).toFixed(2));
        if (totalAmount) {
            setItemTotalAmount(totalAmount);
        } else {
            setItemTotalAmount(0)
        }
        if (isDeleteAll == false) {
            restoreData();
        }
    }, [ptr, qty, disc, gst.name])

    const BankList = async () => {
        let data = new FormData()
        try {
            await axios.post('bank-list', data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
            ).then((response) => {
                setBankData(response.data.data)
            })
        } catch (error) {
            console.error("API error:", error);
        }
    }

    let listOfGst = () => {
        axios.get("gst-list", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                console.log("API Response item Catagory:===", response);
                setGstList(response.data.data);
            })
            .catch((error) => {
                console.log("API Error:", error);
            });
    }

    let listDistributor = () => {
        axios.get("list-distributer", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            localStorage.setItem("distributor", response.data.data.distributor)
            setDistributorList(response.data.data);
        }).catch((error) => {
            console.log("API Error:", error);
        });
    };
    const isDateDisabled = (date) => {
        const today = new Date();
        // Set time to 00:00:00 to compare only date part
        today.setHours(0, 0, 0, 0);

        // Disable dates that are greater than today
        return date > today;
    };
    const deleteOpen = (Id) => {
        setIsDelete(true);
        setItemId(Id);
        console.log(ItemId);
    };

    const handleDeleteItem = async (ItemId) => {
        if (!ItemId) return;
        let data = new FormData();
        data.append("purches_return_id", ItemId);
        const params = {
            purches_return_id: ItemId,
            type: 0
        };
        try {
            await axios.post("purches-return-iteam-delete?", data, {
                params: params,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
            ).then((response) => {
                purcheseReturnFilter();
                setIsDelete(false);
            })
        } catch (error) {
            console.error("API error:", error);
        }
    }

    const filterData = async () => {
        const newErrors = {};
        if (!distributor) newErrors.distributor = 'distributor is required';
        if (!startDate) newErrors.startDate = 'start date is required';
        if (!endDate) newErrors.endDate = 'end date is required';

        setErrors(newErrors);
        const isValid = Object.keys(newErrors).length === 0;
        if (isValid) {
            await purcheseReturnFilter(); // Call handleAddItem if validation passes
        }
        return isValid;
    }

    const purcheseReturnFilter = async () => {
        let data = new FormData();
        setIsLoading(true);
        data.append("start_date", startDate ? format(startDate, 'MM/yy') : '');
        data.append("end_date", endDate ? format(endDate, 'MM/yy') : '');
        data.append("distributor_id", distributor.id);
        try {
            await axios.post("purches-return-filter?", data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            ).then((response) => {
                setReturnItemList(response.data.data)
                // batchListAPI();
                setIsLoading(false);
                console.log(response.data.data.item_list)
                localStorage.setItem('StartFilterDate', startDate ? format(startDate, 'MM-yyyy') : '');
                localStorage.setItem('EndFilterDate', endDate ? format(endDate, 'MM-yyyy') : '');
                localStorage.setItem('DistributorId', distributor.id);
            })
        } catch (error) {
            console.error("API error:", error);
        }
    }


    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSchAmt = (e) => {
        const inputDiscount = parseFloat(e.target.value);
        setDisc(inputDiscount);

        const totalSchAmt = parseFloat((((ptr * inputDiscount) / 100) * qty).toFixed(2));
        setSchAmt(totalSchAmt);

        const totalBase = parseFloat(((ptr * qty) - totalSchAmt).toFixed(2));
        // setBase(totalBase);
    };

    const removeItem = () => {
        setUnit('')
        setBatch('')
        setSearchItem('');
        setExpiryDate('');
        setMRP('')
        setQty('')
        setFree('')
        setPTR('')
        setDisc('')
        setGst('')
        setLoc('')
        setItemTotalAmount(0)
    }

    const handleSubmit = () => {
        const newErrors = {};
        if (!distributor) {
            newErrors.distributor = 'Please select Distributor';
        }
        if (!billNo) {
            newErrors.billNo = 'Bill No is Required';
        }
        // if (!returnType && distributor && billNo) {
        //     newErrors.returnType = 'return Type is Required';
        //     setOpen(true);
        // }
        setError(newErrors);

        if (Object.keys(newErrors).length > 0) {
            return;
        }
        submitPurchaseData();
    }

    const submitPurchaseData = async () => {
        let data = new FormData();
        setIsLoading(true)
        data.append("distributor_id", distributor.id);
        data.append("bill_no", billNo);
        data.append("bill_date", selectedDate ? format(selectedDate, 'yyyy-MM-dd') : '');
        data.append('remark', remark);
        data.append("owner_type", localStorage.getItem('UserName'));
        data.append("purches_return", JSON.stringify(returnItemList.item_list));
        data.append('net_amount', returnItemList.final_amount);
        data.append('final_amount', returnItemList.final_amount);
        data.append('payment_type', paymentType);
        data.append('other_amount', returnItemList.final_amount);
        data.append('discount', returnItemList.final_amount);
        data.append('start_date', startDate ? format(startDate, 'MM-yyyy') : '');
        data.append('end_date', endDate ? format(endDate, 'MM-yyyy') : '');
        try {
            await axios.post("purches-return-store", data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            ).then((response) => {
                console.log(response.data)
                setIsLoading(false)
                toast.success(response.data.message);
                setTimeout(() => {
                    history.push('/purchase/return');
                }, 2000);
            })
        } catch (error) {
            console.error("API error:", error);
        }
    }

    const handleExpiryDateChange = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/\D/g, '');

        if (inputValue.length > 2) {
            const month = inputValue.slice(0, 2);
            const year = inputValue.slice(2, 4);
            if (parseInt(month) > 12) {
                inputValue = 'MM';
            } else if (parseInt(month) < 1) {
                inputValue = '01';
            }
            inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(2, 4)}`;
        }
        setExpiryDate(inputValue);
    };

    const handleEditClick = (item) => {
        setSelectedEditItem(item);
        setSelectedEditItemId(item.id);
        setItemPurchaseId(item.item_id);
        if (selectedEditItem) {
            setSearchItem(selectedEditItem.item_name)
            setUnit(selectedEditItem.weightage);
            setBatch(selectedEditItem.batch_number);
            setExpiryDate(selectedEditItem.expiry);
            setMRP(selectedEditItem.mrp);
            setQty(selectedEditItem.qty);
            setFree(selectedEditItem.fr_qty);
            setPTR(selectedEditItem.ptr);
            setDisc(selectedEditItem.disocunt);
            setGst(gstList.find(option => option.name === selectedEditItem.gst_name) || {});
            setLoc(selectedEditItem.location);
            setItemTotalAmount(selectedEditItem.amount)
        }
    };

    const EditReturn = async () => {
        const newErrors = {};
        if (!unit) newErrors.unit = 'Unit is required';
        if (!batch) newErrors.batch = 'Batch is required';
        if (!expiryDate) newErrors.expiryDate = 'Expiry date is required';
        if (!mrp) newErrors.mrp = 'MRP is required';
        if (!qty) newErrors.qty = 'Quantity is required';
        if (!free) newErrors.free = 'Free quantity is required';
        if (!ptr) newErrors.ptr = 'PTR is required';
        if (!disc) newErrors.disc = 'Discount is required';
        if (!gst.name) newErrors.gst = 'GST is required';
        if (!loc) newErrors.loc = 'Location is required';
        setErrors(newErrors);
        const isValid = Object.keys(newErrors).length === 0;
        if (isValid) {
            await handleEditItem(); // Call handleEditItem if validation passes
        }
        return isValid;

    }

    const restoreData = () => {
        let data = new FormData();
        const params = {
            start_date: localStorage.getItem('StartFilterDate'),
            end_date: localStorage.getItem('EndFilterDate'),
            distributor_id: localStorage.getItem('DistributorId'),
            type: 0
        };
        try {
            const res = axios.post("purches-return-iteam-histroy?", data, {
                params: params,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
            ).then((response) => {
                console.log(response);
                localStorage.removeItem('StartFilterDate')
                localStorage.removeItem('EndFilterDate')
                localStorage.removeItem('DistributorId')
            })
        } catch (error) {
            console.error("API error:", error);
        }
    }

    const handleEditItem = async () => {
        let data = new FormData();
        data.append('purches_return_id', selectedEditItemId)
        data.append('iteam_id', itemPurchaseId)
        data.append("batch", batch)
        data.append("exp_dt", expiryDate)
        data.append("mrp", mrp)
        data.append("ptr", ptr)
        data.append("fr_qty", free)
        data.append("qty", qty)
        data.append("disocunt", disc)
        data.append('gst', gst.id)
        data.append('location', loc)
        data.append('amount', ItemTotalAmount)
        data.append("weightage", unit)
        const params = {
            id: selectedEditItemId
        };
        try {
            const response = await axios.post("purches-return-edit-iteam?", data, {
                params: params,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            console.log("response", response);
            setIsDeleteAll(true);
            purcheseReturnFilter();
            setSearchItem('');
            setUnit('')
            setBatch('')
            setExpiryDate('');
            setMRP('')
            setQty('')
            setFree('')
            setPTR('')
            setGst('')
            setDisc('')
            setBatch('')
            setLoc('')
            setItemTotalAmount(0);
        }
        catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <Header />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {isLoading ? <div className="loader-container ">
                <Loader />
            </div> :
                <div style={{ backgroundColor: 'rgba(153, 153, 153, 0.1)', height: 'calc(99vh - 75px)', padding: "0px 20px 0px" }} >
                    <div>
                        <div className='py-3' style={{ display: 'flex', gap: '4px' }}>
                            <div style={{ display: 'flex', flexWrap: 'wrap', width: '600px', gap: '7px' }}>
                                <span style={{ color: 'rgba(12, 161, 246, 1)', display: 'flex', alignItems: 'center', fontWeight: 700, fontSize: '20px', cursor: "pointer" }} onClick={() => history.push('/purchase/return')}>Purchase</span>
                                <ArrowForwardIosIcon style={{ fontSize: '18px', marginTop: '10px', alignItems: 'center', color: "rgba(4, 76, 157, 1)" }} />
                                <span className='hover:text-blue-900' style={{ color: '#044c9d', display: 'flex', alignItems: 'center', fontWeight: 600, fontSize: '18px', cursor: "pointer" }} onClick={() => history.push('/purchase/return')} >Purchase Return</span>
                                <ArrowForwardIosIcon style={{ fontSize: '18px', marginTop: '10px', alignItems: 'center', color: "rgba(4, 76, 157, 1)" }} />
                                <span className='darkblue_text' style={{ display: 'flex', alignItems: 'center', alignItems: 'center', fontWeight: 600, fontSize: '18px' }}>New </span>
                                <BsLightbulbFill className="mt-1 w-6 h-6 sky_text hover-yellow" />
                            </div>
                            <div className="headerList">
                                {/* <Select
                                    labelId="dropdown-label"
                                    id="dropdown"
                                    value={selectedOption}
                                    sx={{ minWidth: '150px' }}
                                    onChange={handleChange}
                                    size="small"
                                >
                                    {staffOptions.map(option => (
                                        <MenuItem key={option.id} value={option.id}>{option.value}</MenuItem>
                                    ))}
                                </Select> */}
                                <Select
                                    labelId="dropdown-label"
                                    id="dropdown"
                                    value={paymentType}
                                    sx={{ minWidth: '200px' }}
                                    onChange={(e) => { setPaymentType(e.target.value) }}
                                    size="small"
                                >
                                    <MenuItem value="cash">Cash</MenuItem>
                                    <MenuItem value="credit">Credit</MenuItem>
                                    {bankData?.map(option => (
                                        <MenuItem key={option.id} value={option.id}>{option.bank_name}</MenuItem>
                                    ))}
                                </Select>
                                <Button variant="contained" style={{ background: "rgb(4, 76, 157)" }} onClick={handleSubmit}>Save</Button>
                            </div>
                        </div>
                        <div className="bg-white">
                            <div className="firstrow flex">
                                <div className="detail">
                                    <span className="title mb-2">Distributor</span>
                                    <Autocomplete
                                        value={distributor}
                                        sx={{
                                            width: '100%',
                                            minWidth: '300px',
                                            '@media (max-width:600px)': {
                                                minWidth: '250px',
                                            },
                                        }}
                                        size='small'
                                        onChange={(e, value) => setDistributor(value)}
                                        options={distributorList}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    {error.distributor && <span style={{ color: 'red', fontSize: '12px' }}>{error.distributor}</span>}
                                    {errors.distributor && <span style={{ color: 'red', fontSize: '12px' }}>{errors.distributor}</span>}
                                </div>
                                <div className="detail">
                                    <span className="title mb-2">Bill Date</span>
                                    <div>
                                        <DatePicker
                                            className='custom-datepicker '
                                            selected={selectedDate}
                                            onChange={(newDate) => setSelectedDate(newDate)}
                                            dateFormat="dd/MM/yyyy"
                                            filterDate={(date) => !isDateDisabled(date)}
                                        />
                                    </div>
                                </div>
                                <div className="detail">
                                    <span className="title mb-2">Bill No</span>
                                    <TextField
                                        id="outlined-number"
                                        type='number'
                                        size="small"
                                        sx={{ width: '150px' }}
                                        value={billNo}
                                        disabled
                                    />
                                    {error.billNo && <span style={{ color: 'red', fontSize: '12px' }}>{error.billNo}</span>}

                                </div>
                                <div className="detail">
                                    <span className="title mb-2">Start Date</span>
                                    <div >
                                        <DatePicker
                                            className='custom-datepicker '
                                            selected={startDate}
                                            error={!!errors.startDate}
                                            onChange={(newDate) => setStartDate(newDate)}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                        />
                                    </div>
                                </div>
                                <div className="detail">
                                    <span className="title mb-2">End Date</span>
                                    <div >
                                        <DatePicker
                                            className='custom-datepicker '
                                            selected={endDate}
                                            onChange={(newDate) => setEndDate(newDate)}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                        />
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        style={{
                                            minHeight: '38px',
                                            alignItems: "center",
                                            marginTop: "24px",
                                            background: "rgba(4, 76, 157, 1)"
                                        }}
                                        onClick={filterData}
                                    >
                                        <FilterAltIcon size='large' style={{ color: "white", fontSize: '20px' }} /> Filter
                                    </Button>
                                </div>
                                <div>
                                </div>
                                <div>
                                </div>
                            </div>
                            <div className='firstrow flex' style={{ paddingTop: "0" }}>
                                <div className="detail">
                                    <span className="title mb-2">Remark</span>
                                    <TextField
                                        id="outlined-number"
                                        size="small"
                                        sx={{
                                            width: '100%',
                                            minWidth: '300px',
                                            '@media (max-width:600px)': {
                                                minWidth: '250px',
                                            },
                                        }}
                                        value={remark}
                                        onChange={(e) => { setRemark(e.target.value) }}
                                    />
                                </div>
                            </div>
                            <div className='overflow-x-auto mt-4 '>
                                <table className="w-full border-collapse custom-table">
                                    <thead>
                                        <tr>
                                            <th >Item Name</th>
                                            <th >Unit</th>
                                            <th >Batch  </th>
                                            <th >Expiry </ th>
                                            <th >MRP  </th>
                                            <th >Qty. </th>
                                            <th >Free </th>
                                            <th >PTR </ th>
                                            <th >CD%</th>
                                            <th >GST%  </th>
                                            <th >Loc.</th>
                                            <th >Amount </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {returnItemList.length === 0 ? (
                                            <tr>
                                                <td colSpan={12} style={{ textAlign: 'center', fontSize: '16px', fontWeight: 600 }}>No record found</td>
                                            </tr>
                                        ) : (<>
                                            <tr>
                                                <td style={{ width: '500px' }}>
                                                    <div >
                                                        <DeleteIcon className='delete-icon' onClick={removeItem}
                                                        />
                                                        {searchItem}
                                                    </div>
                                                </td>
                                                <td>
                                                    <TextField
                                                        id="outlined-number"
                                                        type="number"
                                                        inputRef={inputRef1}
                                                        onKeyDown={handleKeyDown}
                                                        size="small"
                                                        error={!!errors.unit}
                                                        value={unit}
                                                        sx={{ width: '80px' }}
                                                        onChange={(e) => { setUnit(e.target.value) }}
                                                    />
                                                </td>
                                                <td>

                                                    <TextField
                                                        id="outlined-number"
                                                        type="number"
                                                        // inputRef={inputRef1}
                                                        // onKeyDown={handleKeyDown}
                                                        size="small"
                                                        disabled
                                                        error={!!errors.batch}
                                                        value={batch}
                                                        sx={{ width: '80px' }}
                                                        onChange={(e) => { setBatch(e.target.value) }}
                                                    />
                                                    {/* <Autocomplete
                                                        id="dropdown"
                                                        value={batch}
                                                        onChange={(event, newValue) => {
                                                            setBatch(newValue);
                                                        }}
                                                        error={!!errors.batch}
                                                        options={batchList.map(option => option.batch_number)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                size="small"
                                                                sx={{ minWidth: '100px' }}
                                                            />)}
                                                        size="small"
                                                        sx={{ minWidth: '100px' }}
                                                    /> */}
                                                </td>
                                                <td>
                                                    <TextField
                                                        id="outlined-number"
                                                        disabled
                                                        size="small"
                                                        sx={{ width: '100px' }}
                                                        inputRef={inputRef3}
                                                        onKeyDown={handleKeyDown}
                                                        error={!!errors.expiryDate}
                                                        value={expiryDate}
                                                        onChange={handleExpiryDateChange}
                                                        placeholder="MM/YY"
                                                    />
                                                </td>
                                                <td>
                                                    <TextField
                                                        id="outlined-number"
                                                        type="number"
                                                        sx={{ width: '100px' }}
                                                        size="small"
                                                        inputRef={inputRef4}
                                                        error={!!errors.mrp}
                                                        onKeyDown={handleKeyDown}
                                                        value={mrp}
                                                        onChange={(e) => { setMRP(e.target.value) }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextField
                                                        id="outlined-number"
                                                        type="number"
                                                        sx={{ width: '100px' }}
                                                        size="small"
                                                        inputRef={inputRef5}
                                                        onKeyDown={handleKeyDown}
                                                        error={!!errors.qty}
                                                        value={qty}
                                                        onChange={(e) => { setQty(e.target.value) }}
                                                    />

                                                </td>
                                                <td>
                                                    <TextField
                                                        id="outlined-number"
                                                        size="small"
                                                        type="number"
                                                        sx={{ width: '100px' }}
                                                        value={free}
                                                        inputRef={inputRef6}
                                                        error={!!errors.free}
                                                        onKeyDown={handleKeyDown}
                                                        onChange={(e) => { setFree(e.target.value) }}
                                                    />

                                                </td>
                                                <td>
                                                    <TextField
                                                        id="outlined-number"
                                                        type="number"
                                                        sx={{ width: '100px' }}
                                                        size="small"
                                                        inputRef={inputRef7}
                                                        onKeyDown={handleKeyDown}
                                                        value={ptr}
                                                        error={!!errors.ptr}
                                                        onChange={(e) => setPTR(e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <TextField
                                                        id="outlined-number"
                                                        sx={{ width: '100px' }}
                                                        size="small"
                                                        type="number"
                                                        inputRef={inputRef8}
                                                        onKeyDown={handleKeyDown}
                                                        value={disc}
                                                        error={!!errors.disc}
                                                        onChange={handleSchAmt} />
                                                </td>
                                                <td>
                                                    <Select
                                                        labelId="dropdown-label"
                                                        id="dropdown"
                                                        value={gst.name}
                                                        sx={{ minWidth: '80px' }}
                                                        onChange={(e) => {
                                                            const selectedOption = gstList.find(option => option.name === e.target.value);
                                                            setGst(selectedOption);
                                                        }}
                                                        size="small"
                                                        displayEmpty
                                                        error={!!errors.gst}
                                                    >
                                                        {gstList.map(option => (
                                                            <MenuItem key={option.id} value={option.name}>{option.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </td>
                                                <td>
                                                    <TextField
                                                        id="outlined-number"
                                                        inputRef={inputRef12}
                                                        onKeyDown={handleKeyDown}
                                                        size="small"
                                                        value={loc}
                                                        error={!!errors.loc}
                                                        sx={{ width: '100px' }}
                                                        onChange={(e) => { setLoc(e.target.value) }}
                                                    />
                                                </td>
                                                <td className="total"><span>{ItemTotalAmount}</span></td>
                                            </tr>
                                            <tr >
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <Button variant="contained" color="success" onClick={EditReturn}><ControlPointIcon />Update</Button>
                                                </td>
                                            </tr>


                                            {returnItemList?.item_list.map(item => (
                                                <tr key={item.id} className="item-List" onClick={() => handleEditClick(item)} >

                                                    <td style={{
                                                        display: 'flex', gap: '8px', alignItems: 'center'
                                                    }}>
                                                        < BorderColorIcon color="primary" />
                                                        <DeleteIcon className='delete-icon' onClick={() => deleteOpen(item.id)} />{item.item_name}
                                                    </td>
                                                    <td>{item.weightage}</td>
                                                    <td>{item.batch_number}</td>
                                                    <td>{item.expiry}</td>
                                                    <td>{item.mrp}</td>
                                                    <td>{item.qty}</td>
                                                    <td>{item.fr_qty}</td>
                                                    <td>{item.ptr}</td>
                                                    <td>{item.disocunt}</td>
                                                    <td>{item.gst_name}</td>
                                                    <td>{item.location}</td>
                                                    <td>{item.amount}</td>
                                                </tr>
                                            ))}
                                            < tr >
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal">Total</td>
                                                <td className="amounttotal">{returnItemList.final_amount.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal">Other Amount</td>
                                                <td className="amounttotal">

                                                    <TextField
                                                        id="outlined-number"
                                                        inputRef={inputRef12}
                                                        onKeyDown={handleKeyDown}
                                                        size="small"
                                                        value={otherAmt}
                                                        type="number"
                                                        sx={{ width: '100px' }}
                                                        onChange={(e) => { setOtherAmt(e.target.value) }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal">Less Disc %</td>
                                                <td className="amounttotal">
                                                    <TextField
                                                        id="outlined-number"
                                                        inputRef={inputRef12}
                                                        onKeyDown={handleKeyDown}
                                                        size="small"
                                                        value={otherAmt}
                                                        type="number"
                                                        sx={{ width: '100px' }}
                                                        onChange={(e) => { setOtherAmt(e.target.value) }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal"></td>
                                                <td className="amounttotal">Net Amount</td>
                                                <td className="amounttotal">00.41
                                                </td>
                                            </tr>
                                        </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div >
                </div >
            }
            <Dialog open={open}>
                <DialogContent style={{ fontSize: '20px', }}>
                    <h2>Please select Return Type.</h2>
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button onClick={() => setOpen(false)} variant="contained">
                        OK !
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete PopUP */}
            <div id="modal" value={IsDelete}
                className={`fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif] ${IsDelete ? "block" : "hidden"
                    }`}>
                <div />
                <div className="w-full max-w-md bg-white shadow-lg rounded-md p-4 relative">
                    <svg xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 cursor-pointer absolute top-4 right-4 fill-current text-gray-600 hover:text-red-500 "
                        viewBox="0 0 24 24" onClick={() => setIsDelete(false)}>
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41Z" />
                    </svg>
                    <div className="my-4 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-12 fill-red-500 inline" viewBox="0 0 24 24">
                            <path
                                d="M19 7a1 1 0 0 0-1 1v11.191A1.92 1.92 0 0 1 15.99 21H8.01A1.92 1.92 0 0 1 6 19.191V8a1 1 0 0 0-2 0v11.191A3.918 3.918 0 0 0 8.01 23h7.98A3.918 3.918 0 0 0 20 19.191V8a1 1 0 0 0-1-1Zm1-3h-4V2a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v2H4a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM10 4V3h4v1Z"
                                data-original="#000000" />
                            <path d="M11 17v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Zm4 0v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Z"
                                data-original="#000000" />
                        </svg>
                        <h4 className="text-lg font-semibold mt-6">Are you sure you want to delete it?</h4>
                    </div>
                    <div className="flex gap-5 justify-center">
                        <button type="submit"
                            className="px-6 py-2.5 w-44 items-center rounded-md text-white text-sm font-semibold border-none outline-none bg-red-500 hover:bg-red-600 active:bg-red-500"
                            onClick={() => handleDeleteItem(ItemId)}
                        >Delete</button>
                        <button type="button"
                            className="px-6 py-2.5 w-44 rounded-md text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-900 hover:text-white"
                            onClick={() => setIsDelete(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>

        </>
    )
}
export default AddReturnbill