import Header from "../Header"
import React, { useState, useEffect } from 'react';
import '../Purchase/AddPurchaseReturn.css'

const AddPurchaseReturn = () => {

    const [distributor, setDistributor] = useState('');
    const [billNo, setBillNo] = useState('');
    const [billDate, setBillDate] = useState('');
    const [poS, setPoS] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [owner, setOwner] = useState('');
    const [credit, setCredit] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
    };

    return (
        <form onSubmit={handleSubmit}>
            <label htmlFor="distributor">Distributor:</label>
            <input
                type="text"
                id="distributor"
                value={distributor}
                onChange={(e) => setDistributor(e.target.value)}
            />
            <br />

            <label htmlFor="billNo">Bill No./ Order No.:</label>
            <input
                type="text"
                id="billNo"
                value={billNo}
                onChange={(e) => setBillNo(e.target.value)}
            />
            <br />

            <label htmlFor="billDate">Bill Date:</label>
            <input
                type="date"
                id="billDate"
                value={billDate}
                onChange={(e) => setBillDate(e.target.value)}
            />
            <br />

            <label htmlFor="poS">PO/S:</label>
            <input
                type="text"
                id="poS"
                value={poS}
                onChange={(e) => setPoS(e.target.value)}
            />
            <br />

            <label htmlFor="dueDate">Due Date:</label>
            <input
                type="date"
                id="dueDate"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
            />
            <br />

            <label htmlFor="owner">Owner:</label>
            <input
                type="text"
                id="owner"
                value={owner}
                onChange={(e) => setOwner(e.target.value)}
            />
            <br />

            <label htmlFor="credit">Credit:</label>
            <select id="credit" value={credit} onChange={(e) => setCredit(e.target.value)}>
                <option value=""></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
            <br />

            <button type="submit">Save Purchase  New Distributor</button>
        </form>
    )
}

export default AddPurchaseReturn